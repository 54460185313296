var gridAktuell;
var grid; 

var viewportBerechnen = debounce(function () {
    var breakpoint = window.matchMedia("(min-width: 799px)");

    if (breakpoint.matches) {
        // Wenn der Bildschirm breit genug ist
        grid = true;
        var element = document.querySelector('.aktuell');
        gridAktuell = new Packery(element, {
            itemSelector: '.aktuell__container',
            gutter: '.aktuell__spaltenabstand'
        });

        // Hier prüfen wir, ob das Packery-Layout existiert und zerstören es
        // wenn wir von einer schmaleren Ansicht wechseln
        if (gridAktuell) {
            gridAktuell.destroy();
        }

        // Jetzt wird die neue Packery-Instanz erstellt
        gridAktuell = new Packery(element, {
            itemSelector: '.aktuell__container',
            gutter: '.aktuell__spaltenabstand'
        });
    } else {
        // Wenn der Bildschirm zu schmal ist
        if (grid === true && gridAktuell) {
            gridAktuell.destroy(); 
        }
        grid = false;
    }
}, 200);

window.addEventListener('resize', function () {
    viewportBerechnen();
});

viewportBerechnen(); 
